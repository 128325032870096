import { template as template_e22b42af36c2416680bbecff12f9c9c1 } from "@ember/template-compiler";
const FKLabel = template_e22b42af36c2416680bbecff12f9c9c1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
